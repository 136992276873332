import React from 'react';

export default function Schedule() {
    return (

        <img
            style={{
                width: '100%',
                marginTop: '10px'
            }}
            src="https://globalshielding.s3.amazonaws.com/schedule.PNG"
            alt="schedule"
        />
    );
};

