import { fabric } from 'fabric';

const Grid = (canvas, theme, darkState, editMode, gridSize) => {
    const gridLines = [];
    for (let i = 0; i <= (canvas.width / gridSize); i++) {
        gridLines.push(new fabric.Line([i * gridSize, 0, i * gridSize, canvas.height], {
            stroke: darkState ? (editMode ? theme.palette.grey[800] : '#01557f') : (editMode ? theme.palette.grey[200] : theme.palette.grey[400]),
            selectable: false,
            evented: false
        }));
    }
    
    for (let i = 0; i <= (canvas.height / gridSize); i++) { // Corrected the loop variable
        gridLines.push(new fabric.Line([0, i * gridSize, canvas.width, i * gridSize], {
            stroke: darkState ? (editMode ? theme.palette.grey[800] : '#01557f') : (editMode ? theme.palette.grey[200] : theme.palette.grey[400]),
            selectable: false,
            evented: false
        }));
    }
    const gridGroup = new fabric.Group(gridLines, {
        selectable: false,
        evented: false
    });
    canvas.add(gridGroup);
    return gridGroup;
};

export default Grid;