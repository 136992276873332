import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TextField, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EquipmentServices from '../../services/Equipment.services';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditTaggedOutDescriptionDialog(props) {
    const { user, token, handleOpenSnackbar, setIsLoading} = props;
    const { open, setOpen, taggedOut, setTaggedOutList } = props
    const theme = useTheme();

    const [isValid, setIsValid] = React.useState(true);
    const [errors, setErrors] = React.useState({});

    const initialFormValues = { description: '' };
    const [values, setValues] = React.useState(initialFormValues);

    React.useEffect(() => {
        setValues({ description: taggedOut.description });
    }, [open]);

    const handleClose = () => {
        setOpen(!open);
        setValues(initialFormValues);
    };

    const handleUpdate = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    const handleSubmit = async () => {
        const data = {
            equipment: taggedOut.equipment.id,
            created_by: taggedOut.created_by.id,
            is_resolved: false,
            resolved_by: null,
            description: values.description
        };

        setIsLoading(true);
        try {
            const response = await EquipmentServices.updateEquipmentIssue(taggedOut.id, data, token);
            if (response.status === 200) {
                taggedOut.description = values.description;
                setTaggedOutList((prevTaggedOutList) => {
                    return prevTaggedOutList.map((taggedOutItem) => {
                        if (taggedOutItem.id === taggedOut.id) {
                            return taggedOut;
                        }
                        return taggedOutItem;
                    });
                });
                handleClose();
                setIsLoading(false);
                handleOpenSnackbar('success', 'Issue updated');
            }
        } catch (error) {
            setIsLoading(false);
            handleOpenSnackbar('error', 'Error updating issue');
        }
    };

    const handleValidation = () => {
        let formIsValid = true;

        if (values.description === '') {
            setErrors({ ...errors, description: 'Please enter a description' });
            formIsValid = false;
            setTimeout(() => {
                formIsValid = true;
                setErrors({ ...errors, description: null });
            }, 3000);
        }
        setIsValid(formIsValid)
        setTimeout(() => {
            setIsValid(true);
        }, 3000);
        return formIsValid ? handleSubmit() : null
    };


    return (
        <Box>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{
                    style: {
                        border: `2px solid ${theme.palette.secondary.main}`,
                        boxShadow: 24,
                        width: 300,
                        zIndex: 2000,
                    },
                }}
            >
                <DialogTitle>
                    {taggedOut.equipment.nickname}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus={false}
                        margin="dense"
                        id="description"
                        name='description'
                        label="description"
                        onChange={handleUpdate}
                        value={values.description}
                        type="text"
                        multiline
                        fullWidth
                        rows={4}
                        variant="outlined"
                        helperText={errors.description === null ? '' : errors.description}
                        error={errors.description ? true : false}
                    />
                </DialogContent>
                <DialogActions>
                    {/* <Button color='error' variant='outlined' onClick={handleClose}>Cancel</Button> */}
                    <Button
                        color='secondary'
                        variant="contained"
                        onClick={handleValidation}
                        // startIcon={<WarningIcon />}
                        // endIcon={<WarningIcon />}
                        fullWidth
                        sx={{ m: 2 }}
                    >
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
