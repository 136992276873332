import { fabric } from 'fabric';

const Door = (canvas, theme, darkState) => {
    const gridSize = 50;
    const strokeWidth = 5;
    const offset = 2.5;

    // Create a door line (closed position)
    const doorLine = new fabric.Line([offset, offset, offset, gridSize], {
        stroke: darkState? 'white' : theme.palette.primary.main,
        strokeWidth: strokeWidth,
    });

    // Create a door arc (swing position)
    const doorArc = new fabric.Path(`M ${offset} ${offset} Q ${gridSize} ${offset} ${gridSize} ${gridSize}`, {
        stroke: theme.palette.secondary.main,
        strokeWidth: 1,
        fill: 'transparent',
    });

    // Create a bottom line of the door
    const bottomLine = new fabric.Line([offset, gridSize, gridSize, gridSize], {
        stroke: theme.palette.background.default,
        strokeWidth: strokeWidth,
    });

    // Group the door elements together
    const doorGroup = new fabric.Group([doorLine, doorArc, bottomLine], {
        left: gridSize * 2 - offset, // Position on screen
        top: gridSize * 5 - offset, // Position on screen
        selectable: true,
        hasControls: true,
        hasBorders: true,
    });

    // Add the grouped door to the canvas
    canvas.add(doorGroup);

    // Make the door rotatable and flippable
    doorGroup.set({
        lockScalingFlip: false,
        lockRotation: false,
    });
    

    return doorGroup;
};

export default Door;