import axios from "axios";
import { SERVER } from "./SERVER";
import { TIMEOUT } from "./TIMEOUT";

class EquipmentService {
    getAllTypes(token){
        axios.defaults.headers.common["Authorization"] = "Token " + token;
        return axios.get(`${SERVER}/api/equipment/types`, {timeout: TIMEOUT});
    };
    getAllEquipment(token){
        axios.defaults.headers.common["Authorization"] = "Token " + token;
        return axios.get(`${SERVER}/api/equipment/`, {timeout: TIMEOUT});
    };
    getInspectionItems(token, equipmentTypeId){
        axios.defaults.headers.common["Authorization"] = "Token " + token;
        return axios.get(`${SERVER}/api/equipment/inspection/items/${equipmentTypeId}`, {timeout: TIMEOUT});
    };
    getEquipmentInspections(token, month, year){
        axios.defaults.headers.common["Authorization"] = "Token " + token;
        return axios.get(`${SERVER}/api/equipment/inspections/${month}/${year}`, {timeout: TIMEOUT});
    };
    getEquipmentIssues(token){
        axios.defaults.headers.common["Authorization"] = "Token " + token;
        return axios.get(`${SERVER}/api/equipment/issues`, {timeout: TIMEOUT});
    };
    createEquipmentInspection(data, token){
        axios.defaults.headers.common["Authorization"] = "Token " + token;
        return axios.post(`${SERVER}/api/create/equipment/inspection`, data, {timeout: TIMEOUT});
    };
    deleteEquipmentInspection(id, token){
        axios.defaults.headers.common["Authorization"] = "Token " + token;
        return axios.delete(`${SERVER}/api/equipment/inspection/${id}`);
    };
    createEquipmentIssue(data, token){
        axios.defaults.headers.common["Authorization"] = "Token " + token;
        return axios.post(`${SERVER}/api/create/equipment/issue`, data, {timeout: TIMEOUT});
    };
    updateEquipmentIssue(id, data, token){
        axios.defaults.headers.common["Authorization"] = "Token " + token;
        return axios.put(`${SERVER}/api/equipment/issue/${id}`, data, {timeout: TIMEOUT});
    };

    // createAnnouncement(data, token){
    //     axios.defaults.headers.common["Authorization"] = "Token " + token;
    //     return axios.post(`${SERVER}/api/create/announcement/`, data);
    // };
    // updateAnnouncement(id, data, token){
    //     axios.defaults.headers.common["Authorization"] = "Token " + token;
    //     return axios.put(`${SERVER}/api/announcement/${id}`, data);
    // };
    // deleteAnnouncement(id, token){
    //     axios.defaults.headers.common["Authorization"] = "Token " + token;
    //     return axios.delete(`${SERVER}/api/announcement/${id}`);
    // };
}

export default new EquipmentService();