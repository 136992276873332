import React, { useEffect, useRef, useState } from 'react';
import { fabric } from 'fabric';
import Box from '@mui/material/Box';
import { Tooltip } from '@mui/material';
import { useTheme } from '@emotion/react';
import Grid from '../components/Canvas/Grid';
import Door from '../components/Canvas/Door';
import Window from '../components/Canvas/Window';
import IconButton from '@mui/material/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import { lockIcon, unlockIcon, deleteIcon, cloneIcon } from '../assets/icons/IconVars';
import SpeedDialControls from '../components/Canvas/SpeedDialControls';


let GRIDSIZE = 50;

const commonButtonStyles = {
    position: 'absolute',
    top: 24,
    right: 16,
    borderRadius: '50%',
    padding: '4px',
};

const FabricCanvas = (props) => {
    const { darkState } = props;
    const theme = useTheme();
    const canvasRef = useRef(null);
    const [isDrawingPolygon, setIsDrawingPolygon] = useState(false);
    const [polygon, setPolygon] = useState(null);
    const [hoverLine, setHoverLine] = useState(null);
    const [gridGroup, setGridGroup] = useState(null);

    useEffect(() => {
        const canvasElement = document.getElementById('canvas');
        canvasElement.width = window.innerWidth;
        canvasElement.height = window.innerHeight;

        const canvas = new fabric.Canvas('canvas', {
            backgroundColor: 'transparent',
            selection: true,
        });

        canvasRef.current = canvas;

        const handleResize = () => {
            canvasElement.width = window.innerWidth;
            canvasElement.height = window.innerHeight;
            canvas.setWidth(window.innerWidth);
            canvas.setHeight(window.innerHeight);
            canvas.renderAll();
        };

        window.addEventListener('resize', handleResize);

        // Add object:moving, object:scaling, and object:rotating event listeners here
        canvas.on('object:moving', handleObjectMoving);
        canvas.on('object:scaling', handleObjectScaling);

        return () => {
            canvas.dispose();
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const canvas = canvasRef.current;

        // Remove the existing grid from the canvas
        if (gridGroup) {
            canvas.remove(gridGroup);
        }

        // Create a new grid and add it to the canvas
        const grid = Grid(canvas, theme, darkState, isDrawingPolygon, GRIDSIZE);
        setGridGroup(grid);

    }, [darkState, isDrawingPolygon]);


    const snapToGrid = (value, gridSize) => {
        return Math.round(value / gridSize) * gridSize;
    };

    const handleObjectMoving = (opt) => {
        const target = opt.target;
        target.set({
            left: snapToGrid(target.left, GRIDSIZE),
            top: snapToGrid(target.top, GRIDSIZE)
        });
    };

    const handleObjectScaling = (opt) => {
        const target = opt.target;
        const baseWidth = target.width * target.scaleX;
        const baseHeight = target.height * target.scaleY;
        const roundedWidth = Math.round(baseWidth / 50) * 50;
        const roundedHeight = Math.round(baseHeight / 50) * 50;
        target.set({
            scaleX: roundedWidth / target.width,
            scaleY: roundedHeight / target.height
        });
    };

    const startDrawingPolygon = () => {
        setPolygon(null);
        setIsDrawingPolygon(true);
        setHoverLine(null);
    };

    const finishDrawingPolygon = () => {
        setIsDrawingPolygon(false);
        setHoverLine(null);
        if (polygon) {
            const points = polygon.points;

            // Calculate the bounding box dimensions without altering polygon's position
            const minX = Math.min(...points.map(p => p.x));
            const minY = Math.min(...points.map(p => p.y));
            const maxX = Math.max(...points.map(p => p.x));
            const maxY = Math.max(...points.map(p => p.y));

            polygon.set({
                selectable: true,
                evented: true,
                hasControls: true,
                hasBorders: true,
                strokeUniform: true,
                lockRotation: true,
                width: maxX - minX,
                height: maxY - minY
            });

            polygon.setCoords();

            // Clone the polygon
            polygon.clone((cloned) => {
                cloned.set({
                    left: polygon.left,
                    top: polygon.top,
                    fill: polygon.fill,
                    stroke: polygon.stroke,
                    strokeWidth: polygon.strokeWidth,
                    objectCaching: polygon.objectCaching,
                    cornerColor: polygon.cornerColor,
                    cornerStyle: polygon.cornerStyle,
                    transparentCorners: polygon.transparentCorners,
                    selectable: polygon.selectable,
                    evented: polygon.evented,
                    strokeUniform: polygon.strokeUniform,
                    hasControls: polygon.hasControls,
                    hasBorders: polygon.hasBorders,
                    lockRotation: polygon.lockRotation,
                });
                const canvas = canvasRef.current;
                canvas.add(cloned);
                addCustomControls(cloned);
                canvas.renderAll();
            });

            // Remove the original polygon from the canvas
            const canvas = canvasRef.current;
            canvas.remove(polygon);
            canvas.renderAll();
        }
    };

    const addPolygonPoint = (opt) => {
        const canvas = canvasRef.current;
        const pointer = canvas.getPointer(opt.e);
        const snappedX = Math.round(pointer.x / GRIDSIZE) * GRIDSIZE;
        const snappedY = Math.round(pointer.y / GRIDSIZE) * GRIDSIZE;

        if (!polygon) {
            const newPolygon = new fabric.Polygon([{ x: snappedX, y: snappedY }], {
                left: snappedX,
                top: snappedY,
                fill: 'transparent',
                stroke: darkState ? 'white' : theme.palette.primary.main,
                strokeWidth: 5,
                objectCaching: false,
                cornerColor: theme.palette.secondary.main,
                cornerStyle: 'square',
                transparentCorners: false,
                selectable: false,
                evented: false,
                strokeUniform: true,
            });
            setPolygon(newPolygon);
            canvas.add(newPolygon);
        } else {
            const updatedPoints = [...polygon.points, { x: snappedX, y: snappedY }];
            polygon.set({ points: updatedPoints });
            polygon.setCoords();
            canvas.renderAll();
        }
    };

    const updateHoverLine = (opt) => {
        const canvas = canvasRef.current;
        const pointer = canvas.getPointer(opt.e);
        const snappedX = Math.round(pointer.x / GRIDSIZE) * GRIDSIZE;
        const snappedY = Math.round(pointer.y / GRIDSIZE) * GRIDSIZE;

        if (polygon) {
            const lastPoint = polygon.points[polygon.points.length - 1];
            if (!hoverLine) {
                const newHoverLine = new fabric.Line([lastPoint.x, lastPoint.y, snappedX, snappedY], {
                    stroke: darkState ? 'white' : theme.palette.primary.main,
                    strokeWidth: 5,
                    selectable: false,
                    evented: false,
                });
                setHoverLine(newHoverLine);
                canvas.add(newHoverLine);
            } else {
                hoverLine.set({
                    x1: lastPoint.x,
                    y1: lastPoint.y,
                    x2: snappedX,
                    y2: snappedY,
                });
                canvas.renderAll();
            }
        }
    };

    const svgToImageSrc = (svgString) => {
        const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
        const url = URL.createObjectURL(svgBlob);
        return url;
    };

    const loadImage = (src) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = () => reject(new Error('Failed to load image'));
            img.src = src;
        });
    };

    const addCustomControls = async (object) => {
        var deleteImg = new Image();
        deleteImg.src = deleteIcon;

        var cloneImg = new Image();
        cloneImg.src = cloneIcon;

        object.transparentCorners = false;
        object.centeredRotation = true;

        object.controls = {
            ...object.controls,
            deleteControl: new fabric.Control({
                x: 0.5,
                y: -0.5,
                offsetY: -16,
                offsetX: 16,
                cursorStyle: 'pointer',
                mouseUpHandler: deleteObject,
                render: renderIcon(deleteImg),
                cornerSize: 24
            })
        };

        if (object.type === 'polygon') {
            const lockIconSrc = svgToImageSrc(unlockIcon);
            const lockImg = await loadImage(lockIconSrc);

            object.controls = {
                ...object.controls,
                cloneControl: new fabric.Control({
                    x: -0.5,
                    y: -0.5,
                    offsetY: -16,
                    offsetX: -16,
                    cursorStyle: 'pointer',
                    mouseUpHandler: lockObject,
                    render: renderIcon(lockImg),
                    cornerSize: 24,
                    bringToBack: true
                })
            };
        } else {
            object.controls = {
                ...object.controls,
                cloneControl: new fabric.Control({
                    x: -0.5,
                    y: -0.5,
                    offsetY: -16,
                    offsetX: -16,
                    cursorStyle: 'pointer',
                    mouseUpHandler: cloneObject,
                    render: renderIcon(cloneImg),
                    cornerSize: 24
                })
            };
            object.bringToFront();
        }

        object.setCoords();
        canvasRef.current.renderAll();
    };

    const setCommonControls = async (object) => {
        var deleteImg = new Image();
        deleteImg.src = deleteIcon;

        var cloneImg = new Image();
        cloneImg.src = cloneIcon;

        object.transparentCorners = false;
        object.centeredRotation = true;

        object.controls = {
            ...object.controls,
            deleteControl: new fabric.Control({
                x: 0.5,
                y: -0.5,
                offsetY: -16,
                offsetX: 16,
                cursorStyle: 'pointer',
                mouseUpHandler: deleteObject,
                render: renderIcon(deleteImg),
                cornerSize: 24
            }),
            cloneControl: new fabric.Control({
                x: -0.5,
                y: -0.5,
                offsetY: -16,
                offsetX: -16,
                cursorStyle: 'pointer',
                mouseUpHandler: cloneObject,
                render: renderIcon(cloneImg),
                cornerSize: 24
            })
        };

        object.on('rotating', function () {
            var angle = this.angle % 360; // Ensure the angle is between 0 and 360
            angle = angle < 0 ? 360 + angle : angle; // Convert negative angles to positive angles
            this.angle = Math.round(angle / 45) * 45; // Round to the nearest multiple of 45

            if (this.angle % 90 === 0) {
                this.originX = null;
                this.originY = null;
                // Snap the new position to the grid
                const rotated = this.angle % 360 !== 0;
                console.log(rotated);
                this.left = snapToGrid(this.left, GRIDSIZE);
                this.top = snapToGrid(this.top, GRIDSIZE);
            }
            else if (this.angle % 45 === 0) {
                this.originX = 'center';
                this.originY = 'center';
                // Snap the new position to the grid
                // this.originX = originalCenterX * 2;
                // this.originY = originalCenterY  * 2;
                const rotated = this.angle % 360 !== 0;
                console.log(rotated);
                this.left = snapToGrid(this.left, GRIDSIZE);
                this.top = snapToGrid(this.top, GRIDSIZE);
            }
        });

        object.cornerColor = theme.palette.secondary.main;
        object.cornerStyle = 'square';
        object.transparentCorners = false;
        object.strokeUniform = true;
        object.bringToFront();
        canvasRef.current.renderAll();
    };


    const lockObject = async (eventData, transform) => {
        const target = transform.target;
        const isLocked = target.lockMovementX && target.lockMovementY && target.lockScalingX && target.lockScalingY && target.lockRotation;

        target.set({
            lockMovementX: !isLocked,
            lockMovementY: !isLocked,
            lockScalingX: !isLocked,
            lockScalingY: !isLocked,
            lockRotation: !isLocked,
        });

        target.setCoords();

        const lockIconSrc = svgToImageSrc(isLocked ? unlockIcon : lockIcon);
        const lockImg = await loadImage(lockIconSrc);

        target.controls.cloneControl = new fabric.Control({
            x: -0.5,
            y: -0.5,
            offsetY: -16,
            offsetX: -16,
            cursorStyle: 'pointer',
            mouseUpHandler: lockObject,
            render: renderIcon(lockImg),
            cornerSize: 24
        });
        canvasRef.current.renderAll();
    };

    const deleteObject = (eventData, transform) => {
        const target = transform.target;
        const canvas = target.canvas;
        canvas.remove(target);
        canvas.requestRenderAll();
    };

    const cloneObject = (eventData, transform) => {
        const target = transform.target;
        console.log(target);
        const canvas = target.canvas;
        target.clone((cloned) => {
            cloned.set({
                fill: target.fill,
                stroke: target.stroke,
                strokeWidth: target.strokeWidth,
                objectCaching: target.objectCaching,
                cornerColor: target.cornerColor,
                cornerStyle: target.cornerStyle,
                transparentCorners: target.transparentCorners,
                selectable: target.selectable,
                evented: target.evented,
                strokeUniform: target.strokeUniform,
            });
            canvas.add(cloned);
            addCustomControls(cloned);
            canvas.requestRenderAll();
        });
    };

    const renderIcon = (icon) => {
        return function renderIcon(ctx, left, top, styleOverride, fabricObject) {
            const size = this.cornerSize;
            ctx.save();
            ctx.translate(left, top);
            ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
            ctx.drawImage(icon, -size / 2, -size / 2, size, size);
            ctx.restore();
        }
    };

    useEffect(() => {
        if (!isDrawingPolygon) return;

        const canvas = canvasRef.current;

        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                setIsDrawingPolygon(false);
                finishDrawingPolygon();
                if (hoverLine) {
                    canvas.remove(hoverLine);
                    setHoverLine(null);
                    canvas.renderAll();
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        canvas.on('mouse:down', addPolygonPoint);
        canvas.on('mouse:move', updateHoverLine);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            canvas.off('mouse:down', addPolygonPoint);
            canvas.off('mouse:move', updateHoverLine);
        };
    }, [isDrawingPolygon, polygon, hoverLine, darkState, theme]);

    useEffect(() => {
        const canvas = canvasRef.current;

        if (isDrawingPolygon) {
            canvas.defaultCursor = 'crosshair'; // Change cursor to crosshair or another cursor type to indicate drawing mode
            canvas.getElement().style.cursor = 'crosshair'; // Force cursor change
        } else {
            canvas.defaultCursor = 'default'; // Reset cursor to default
            canvas.getElement().style.cursor = 'default'; // Force cursor reset
        }
    }, [isDrawingPolygon]);

    useEffect(() => {
        const canvas = canvasRef.current;

        const selectObjectOnClick = (opt) => {
            const target = opt.target;
            if (target) {
                canvas.setActiveObject(target);
                canvas.renderAll();
            }
        };

        canvas.on('mouse:down', selectObjectOnClick);

        return () => {
            canvas.off('mouse:down', selectObjectOnClick);
        };
    }, []);

    //* actions for SpeedDialControls

    const flipDoor = () => {
        console.log('Flipping door');
    };

    const handleAddDoor = () => {
        console.log('Adding door');
        const canvas = canvasRef.current;
        const door = Door(canvas, theme, darkState);
        setCommonControls(door);
    };

    const handleAddWindow = () => {
        console.log('Adding window');
        const canvas = canvasRef.current;
        const windowObj = Window(canvas, theme, darkState);
        setCommonControls(windowObj);
    };

    const handleAddPenPanel = () => {
        console.log('Adding pen panel');
    };

    const handleSaveImage = () => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        // Store original stroke colors and change all shapes' stroke to black
        const originalStrokeColors = canvas.getObjects().map((object) => {
            const originalStrokeColor = object.stroke;
            object.set('stroke', 'black');
            return originalStrokeColor;
        });

        canvas.renderAll(); // Apply the color changes

        // Remove the grid group
        if (gridGroup) {
            canvas.remove(gridGroup);
        }

        // Convert canvas to data URL
        const dataUrl = canvas.toDataURL({
            format: 'png',
            quality: 1.0
        });

        // Add the grid group back
        if (gridGroup) {
            canvas.add(gridGroup);
        }

        // Restore original stroke colors
        canvas.getObjects().forEach((object, index) => {
            object.set('stroke', originalStrokeColors[index]);
        });

        canvas.renderAll(); // Apply the color changes

        // Create a link element
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'canvas.png';

        // Simulate a click on the link to trigger the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };



    return (
        <Box sx={{ position: 'relative', transform: 'translateZ(0px)', flexGrow: 1 }}>
            <canvas id="canvas" style={{ width: '100%', height: '100%' }}></canvas>
            <SpeedDialControls
                darkState={darkState}
                handleAddDoor={handleAddDoor}
                handleAddWindow={handleAddWindow}
                handleAddPenPanel={handleAddPenPanel}
                handleSaveImage={handleSaveImage}
            />
            {isDrawingPolygon ? (
                <Box
                    sx={{
                        ...commonButtonStyles,
                        backgroundColor: 'grey.500',
                    }}
                >
                    <IconButton
                        color="inherit"
                        size="large"
                        onClick={finishDrawingPolygon}
                    >
                        <CreateIcon sx={{ fontSize: 25, color: 'white' }} />
                    </IconButton>
                </Box>
            ) : (
                <Box
                    sx={{
                        ...commonButtonStyles,
                        backgroundColor: 'primary.main',
                    }}
                >
                    <Tooltip title="Add Walls" placement="bottom">
                        <IconButton
                            color="inherit"
                            size="large"
                            onClick={startDrawingPolygon}
                            disabled={isDrawingPolygon}
                        >
                            <CreateIcon sx={{ fontSize: 25, color: 'white' }} />
                        </IconButton>
                    </Tooltip>
                </Box>
            )}
        </Box>
    );
};

export default FabricCanvas;