import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TextField, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EquipmentServices from '../../services/Equipment.services';
import TaskServices from '../../services/Task.services';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function TaggedOutDialog(props) {
    const { user, token, handleOpenSnackbar, setIsLoading} = props;
    const { open, setOpen, setEquipmentDialog, equipment, setTaggedOutList } = props
    const theme = useTheme();

    const [isValid, setIsValid] = React.useState(true);
    const [errors, setErrors] = React.useState({});

    const initialFormValues = { description: '' };
    const [values, setValues] = React.useState(initialFormValues);

    const handleClose = () => {
        setOpen(false);
        setValues(initialFormValues);
        setEquipmentDialog(false)
    };

    const handleUpdate = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            const data = {
                equipment: equipment.id,
                created_by: user.id,
                description: values.description,
                date: new Date(),
                resolved_by: null,
                is_resolved: false,
            };
            const response = await EquipmentServices.createEquipmentIssue(data, token);
            setTaggedOutList(prevData => [...prevData, response.data]);
            createTask();
            setIsLoading(false);
            handleOpenSnackbar('success', 'Equipment Tagged Out Successfully');
        } 
        catch (error) {
            handleOpenSnackbar('error', 'Error creating equipment issue');
            setIsLoading(false);
        }
        handleClose();
    };

    const handleValidation = () => {
        let formIsValid = true;

        if (values.description === '') {
            setErrors({ ...errors, description: 'Please enter a description' });
            formIsValid = false;
            setTimeout(() => {
                formIsValid = true;
                setErrors({ ...errors, description: null });
            }, 3000);
        }
        setIsValid(formIsValid)
        setTimeout(() => {
            setIsValid(true);
        }, 3000);
        return formIsValid ? handleSubmit() : null
    };

    const createTask = async () => {
        const data = {
            created_by: user.id,
            assignee: equipment.manager.id,
            tasklist: 8,
            title:`Equipment Issue: ${equipment.nickname} ${equipment.make} ${equipment.model}`,
            notes: values.description,
            due: new Date(),
            subtasks:[],
            project: 12,
            service: '',
            hse: '',
            quote:'',
            created: new Date(),
            is_complete: false,
            is_deleted: false,
            is_read: false,
            is_public: true,
            completed: new Date(),
            updated: new Date(),
            attachments: []
        };
        try {
            await TaskServices.createTask(data, token);
            handleOpenSnackbar('success', 'Task Created Successfully');
        } 
        catch (error) {
            console.log(error);
            handleOpenSnackbar('error', 'Error creating task');
        }
    }


    return (
        <Box>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{
                    style: {
                        border: `2px solid ${theme.palette.warning.main}`,
                        boxShadow: 24,
                        width: 300,
                    },
                }}
            >
                <DialogTitle 
                    sx={{color: theme.palette.warning.main}}
                >
                    {equipment.nickname}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus={false}
                        margin="dense"
                        id="description"
                        name='description'
                        label="description"
                        onChange={handleUpdate}
                        value={values.description} 
                        type="text"
                        multiline
                        fullWidth
                        rows={4}
                        variant="outlined"
                        helperText={errors.description === null ? '' : errors.description}
                        error={errors.description ? true : false}
                    />
                </DialogContent>
                <DialogActions>
                    {/* <Button color='error' variant='outlined' onClick={handleClose}>Cancel</Button> */}
                    <Button
                        color='warning'
                        variant="contained"
                        onClick={handleValidation}
                        // startIcon={<WarningIcon />}
                        // endIcon={<WarningIcon />}
                        fullWidth
                        sx={{ m: 2 }}
                    >
                        Tag Out
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
