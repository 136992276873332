import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useTheme } from '@mui/material/styles';
import { Stack, Divider, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import EditTaggedOutDescriptionDialog from './EditTaggedOutDescriptionDialog';
import EquipmentServices from '../../services/Equipment.services';

export default function ResolveTaggedOutModal(props) {
    const { user, token, handleOpenSnackbar, setIsLoading } = props;
    const { open, setOpen, equipment, taggedOutList, setTaggedOutList, handleResolveTaggedOut } = props;
    const [taggedOut, setTaggedOut] = React.useState(null);
    const [resolved, setResolved] = React.useState(null);
    const [isEditable, setIsEditable] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);

    const theme = useTheme();

    React.useEffect(() => {
        taggedOutList.forEach((taggedOutItem) => {
            if (taggedOutItem.equipment.id === equipment.id && !taggedOutItem.is_resolved) {
                setTaggedOut(taggedOutItem);
            }
        });
    }, [open, taggedOutList, equipment, openEdit]);

    React.useEffect(() => {
        if (taggedOut && user.id === taggedOut.created_by.id) {
            setIsEditable(true);
        } else {
            setIsEditable(false);
        }
    }, [taggedOut, user]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        border: `2px solid ${theme.palette.warning.main}`,
        boxShadow: 24,
        p: 4,
        zIndex: 1000,
    };

    const handleClose = () => {
        setOpen(false);
        setTaggedOut(null);
        setIsEditable(false);
    };

    const handleEdit = () => {
        setOpenEdit(!openEdit);
        setOpen(false);
    };

    const handleResolveIssue = async (event, newValue) => {
        const data = taggedOut;
        data.equipment = taggedOut.equipment.id;
        data.created_by = taggedOut.created_by.id;
        data.is_resolved = true;
        data.resolved_by = user.id;
        setIsLoading(true);
        try {
            const response = await EquipmentServices.updateEquipmentIssue(taggedOut.id, data, token);
            if (response.status === 200) {
                const updatedTaggedOut = response.data; 


                setResolved(newValue);
                if (newValue === false) {
                    handleClose();
                    setResolved(null);
                } else if (newValue === true) {
                    handleResolveTaggedOut(equipment);
                    setOpen(false);
                    setResolved(null);
                }
                // Update taggedOutList
                setTaggedOutList(prevTaggedOutList => {
                    return prevTaggedOutList.map(item => {
                        if (item.id === taggedOut.id) {
                            return updatedTaggedOut;
                        }
                        return item;
                    });
                });
                setIsLoading(false);
                handleOpenSnackbar('success', 'Issue resolved');
            }
        } catch (error) {
            setIsLoading(false);
            handleOpenSnackbar('error', 'Error resolving issue');
        }
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Stack sx={{ mb: 3 }}>
                        <Typography variant='h6'>
                            Issue Description:
                        </Typography>
                        <Stack direction='row' spacing={1}>
                            {isEditable && (
                                <IconButton
                                    size='small'
                                    sx={{ width: '20px', height: '20px', p: 0 }}
                                    onClick={handleEdit}
                                >
                                    <EditIcon
                                        color='primary'
                                        fontSize="small"
                                        sx={{ width: '15px', height: '15px', p: 0 }}
                                    />
                                </IconButton>
                            )}
                            <Typography variant="body2" color='textSecondary'>
                                {taggedOut ? taggedOut.description : 'No description provided'}
                            </Typography>
                        </Stack>
                    </Stack >
                    <Divider />
                    <Typography
                        sx={{ mb: 3, mt: 3 }}
                        variant="body2"
                    >
                        {`${equipment.nickname} is tagged out. Would you like to resolve this issue?`}
                    </Typography>
                    <ToggleButtonGroup
                        fullWidth
                        color="primary"
                        value={resolved}
                        exclusive
                        onChange={handleResolveIssue}
                        aria-label="Platform"
                    >
                        <ToggleButton value={true}>Yes</ToggleButton>
                        <ToggleButton value={false}>No</ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </Modal>
            {isEditable &&
                <EditTaggedOutDescriptionDialog
                    user={user}
                    token={token}
                    handleOpenSnackbar={handleOpenSnackbar}
                    setIsLoading={setIsLoading}
                    open={openEdit}
                    setOpen={setOpenEdit}
                    taggedOut={taggedOut}
                    setTaggedOutList={setTaggedOutList}
                // handleClose={handleClose}
                />
            }
        </div>
    );
};