import React, { useState, useRef } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Stack } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import WarningIcon from '@mui/icons-material/Warning';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Avatar from '@mui/material/Avatar';
import { getDatesForMonth, getOrdinalSuffix } from '../../utilities/DateFunctions';
import { stringAvatar, } from '../../utilities/AvatarModifiers';
import { Button } from '@mui/material';
import EquipmentInspectionDialog from './EquipmentInspectionDialog';
import EquipmentDetailsDialog from './EquipmentDetailsDialog';
import ResolveTaggedOutModal from './ResolveTaggedOutModal';
import EquipmentServices from '../../services/Equipment.services';


function CustomCheckbox({ checked, created_by, user, disabled, ...props }) {
    return (
        <MouseOverPopover
            checked={checked}
            created_by={created_by ? `${created_by.first_name} ${created_by.last_name}` : ''}
        >
            <IconButton 
                disabled={(checked && created_by && created_by.id !== user.id) || disabled}
                {...props}
            >
                {checked ?
                    created_by ?
                        <Avatar
                            {...stringAvatar(`${created_by.first_name} ${created_by.last_name}`)}
                        />
                        :
                        null
                    :
                    <CheckBoxOutlineBlankIcon />
                }
            </IconButton>
        </MouseOverPopover>
    );
};

function CustomCalendar(props) {
    const { user, token, handleOpenSnackbar, setIsLoading } = props;
    const { dates, setOpen, currentMonth, currentYear } = props;
    const { clickedCheckbox, setClickedCheckbox, setEquipment, equipment, taggedOutList, setTaggedOutList } = props;
    const { equipmentList } = props;
    const { checked, setChecked } = props;
    const { inspectionData, setInspectionData } = props;

    const [openEquipmentDetails, setOpenEquipmentDetails] = React.useState(false);
    const [openResolveTaggedOut, setOpenResolveTaggedOut] = React.useState(false);

    const tableRef = useRef(null);
    const equipmentCellRef = useRef(null);
    const theme = useTheme();

    const today = new Date();
    const todayOrdinal = getOrdinalSuffix(today.getDate());
    const todayMonth = today.getMonth(); // getMonth() returns a zero-based month, so add 1
    const todayYear = today.getFullYear();

    const todayIndex = dates.findIndex(date => date === todayOrdinal);
    const cellWidth = 72; // The width of the cells in pixels
    const scrollPosition = todayIndex * cellWidth;

    const inspectionDates = inspectionData.map(inspection => {
        const date = new Date(inspection.date);
        date.setMinutes(date.getMinutes() + date.getTimezoneOffset()); // Adjust for timezone
        return `${getOrdinalSuffix(date.getDate())}-${date.getMonth() + 1}-${date.getFullYear()}-${inspection.equipment.id}`;
    });

    React.useEffect(() => {
        const newChecked = {};
        inspectionData.forEach(inspection => {
            const inspectionDate = new Date(inspection.date);
            inspectionDate.setMinutes(inspectionDate.getMinutes() + inspectionDate.getTimezoneOffset()); // Adjust for timezone
            const key = `${getOrdinalSuffix(inspectionDate.getDate())}-${inspectionDate.getMonth() + 1}-${inspectionDate.getFullYear()}-${inspection.equipment.id}`;
            newChecked[key] = true;
        });
        setChecked(newChecked);
    }, [inspectionData]);


    React.useEffect(() => {
        // Scroll to today's date if it's in the current month
        const isToday = getOrdinalSuffix(today.getDate()) === todayOrdinal && currentMonth === todayMonth && currentYear === todayYear;

        if (tableRef.current && isToday) {
            tableRef.current.scrollLeft = scrollPosition;
        } else {
            tableRef.current.scrollLeft = 0;
        }
    }, [currentMonth, currentYear]);


    const handleCheckboxClick = async (e, date, equipment) => {
        e.preventDefault();
        const day = parseInt(date.replace(/\D/g, ''));
        const month = (currentMonth + 1).toString().padStart(2, '0'); // Pad with leading zero
        const year = currentYear;
        const totalDate = `${year}-${month}-${day.toString().padStart(2, '0')}`; // Pad with leading zero

        const inspection = inspectionData.find(i => i.equipment.id === equipment.id && i.date == totalDate);

        const key = `${date}-${currentMonth + 1}-${currentYear}-${equipment.id}`;
        if (checked[key]) {
            // If already checked, try to delete the inspection
            setIsLoading(true);
            try {

                if (inspection) {
                    await EquipmentServices.deleteEquipmentInspection(inspection.id, token);

                    // If successful, uncheck it and remove the inspection from inspectionData
                    setChecked(prevChecked => {
                        const newChecked = { ...prevChecked };
                        delete newChecked[key];
                        return newChecked;
                    });
                    setInspectionData(prevInspectionData => prevInspectionData.filter(i => i.id !== inspection.id));
                    setEquipment({});
                    setIsLoading(false);
                    handleOpenSnackbar('success', 'Inspection deleted');
                }
            } catch (error) {
                setIsLoading(false);
                handleOpenSnackbar('error', 'Error deleting inspection');
            }
        } else {
            // If not checked, store the checkbox info and open the dialog
            setClickedCheckbox({ date, equipment });
            setEquipment(equipment)
            setOpen(true);
        }
    };

    const handleOpenDetails = (equipment) => {
        setOpenEquipmentDetails(true)
        setEquipment(equipment)
    };

    const handleResolveTaggedOut = (equipment) => {
        setTaggedOutList(prevTaggedOutList => prevTaggedOutList.map(taggedOut => {
            if (taggedOut.equipment.id === equipment.id && taggedOut.is_resolved === false) {
                taggedOut.is_resolved = true;
                taggedOut.resolved_by = user;
                taggedOut.resolved_date = new Date();
            }
            return taggedOut;
        }));
    };


    const isUserInManagementGroup = user.groups.some(group => group.name === 'MANAGEMENT');

    return (
        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
            <TableContainer component={Paper} ref={tableRef} onScroll={() => {
                if (equipmentCellRef.current) {
                    const scrollLeft = Math.round(tableRef.current.scrollLeft);
                    equipmentCellRef.current.style.transform = `translateX(${scrollLeft}px)`;
                }
            }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell component="th" scope="row" style={{ position: 'sticky', left: 0, backgroundColor: theme.palette.background.paper, zIndex: 1 }}>
                                <Typography>
                                    Equipment
                                </Typography>
                            </TableCell>
                            {dates.map((date, index) => {
                                const isToday = date === todayOrdinal && currentMonth === todayMonth && currentYear === todayYear;

                                return (
                                    <TableCell
                                        key={index}
                                        align="center"
                                        style={{
                                            width: '72px',
                                            borderBottom: isToday ? 'none' : '',
                                            border: isToday ? `1px solid ${theme.palette.primary.main}` : undefined,
                                            borderRadius: isToday ? '50%' : undefined
                                        }}
                                    >
                                        <Typography>
                                            {date}
                                        </Typography>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {equipmentList.map((equipment, descIndex) => {
                            const isTaggedOut = taggedOutList.some(item => item.equipment.id === equipment.id && item.is_resolved === false);
                            return (
                                <TableRow key={descIndex}>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        style={{
                                            position: 'sticky',
                                            left: 0,
                                            backgroundColor: theme.palette.background.paper,
                                            zIndex: 1, borderBottom: descIndex === equipmentList.length - 1 ? 'none' : '',
                                            textDecoration: isTaggedOut ? 'line-through' : 'none'
                                        }}>
                                        <Button
                                            disabled={!isUserInManagementGroup}
                                            onClick={() => { handleOpenDetails(equipment) }}
                                            sx={{ textTransform: 'none', textDecoration: isTaggedOut ? 'line-through' : 'none' }}
                                        >
                                            <Stack spacing={0}>
                                                <Typography color={isTaggedOut ? 'error' : ''}>
                                                    {equipment.nickname}
                                                </Typography>
                                                <Typography
                                                    color={isTaggedOut ? '#ff9800' : 'textSecondary'}
                                                    variant="body2"
                                                >
                                                    {equipment.make} {equipment.model}
                                                </Typography>
                                            </Stack>
                                        </Button>
                                    </TableCell>
                                    {dates.map((date, dateIndex) => (
                                        <TableCell
                                            key={dateIndex}
                                            align="center"
                                            style={{
                                                width: '50px',
                                                borderBottom: descIndex === equipmentList.length - 1 ? 'none' : '',
                                            }}>
                                            {isTaggedOut && (date === todayOrdinal && currentMonth === todayMonth && currentYear === todayYear) ?
                                                isUserInManagementGroup ?
                                                    <IconButton
                                                        color='warning'
                                                        onClick={() => {
                                                            setOpenResolveTaggedOut(true)
                                                            setEquipment(equipment)
                                                        }}
                                                    >
                                                        <WarningIcon />
                                                    </IconButton>
                                                    : <WarningIcon color='warning' />
                                                :
                                                <CustomCheckbox
                                                    user={user}
                                                    created_by={(inspectionData.find(inspection => {
                                                        const inspectionDate = new Date(inspection.date);
                                                        inspectionDate.setMinutes(inspectionDate.getMinutes() + inspectionDate.getTimezoneOffset()); // Adjust for timezone
                                                        const inspectionKey = `${getOrdinalSuffix(inspectionDate.getDate())}-${inspectionDate.getMonth() + 1}-${inspectionDate.getFullYear()}-${inspection.equipment.id}`;
                                                        return inspectionKey === `${date}-${currentMonth + 1}-${currentYear}-${equipment.id}`;
                                                    }) || {}).created_by}
                                                    onClick={(e) => { handleCheckboxClick(e, date, equipment,) }}
                                                    checked={checked[`${date}-${equipment.nickname}`] || inspectionDates.includes(`${date}-${currentMonth + 1}-${currentYear}-${equipment.id}`)}
                                                    disabled={!(checked[`${date}-${equipment.nickname}`] || (date === todayOrdinal && currentMonth === todayMonth && currentYear === todayYear)) ||  equipment.tagged_out }
                                                />
                                            }
                                        </TableCell>
                                    ))}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <EquipmentDetailsDialog
                open={openEquipmentDetails}
                setOpen={setOpenEquipmentDetails}
                equipment={equipment}
                taggedOutList={taggedOutList}
            />
            <ResolveTaggedOutModal
                user={user}
                token={token}
                handleOpenSnackbar={handleOpenSnackbar}
                setIsLoading={setIsLoading}
                open={openResolveTaggedOut}
                setOpen={setOpenResolveTaggedOut}
                equipment={equipment}
                taggedOutList={taggedOutList}
                setTaggedOutList={setTaggedOutList}
                handleResolveTaggedOut={handleResolveTaggedOut}
            />
        </div>
    );
};


function MouseOverPopover({ children, checked, created_by }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);


    return (
        <div
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
        >
            {children}
            {checked && (
                <Popover
                    id="mouse-over-popover"
                    sx={{
                        pointerEvents: 'none',
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <Typography sx={{ p: 1 }}>{created_by}</Typography>
                </Popover>
            )}
        </div>
    );
}

export default function EquipmentCalendar(props) {
    const { user, token, handleOpenSnackbar, category } = props;
    const { isLoading, setIsLoading } = props;
    const [inspectionData, setInspectionData] = React.useState([]);
    const [equipmentList, setEquipmentList] = React.useState([]);
    const [equipment, setEquipment] = React.useState([]);

    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [open, setOpen] = React.useState(false);

    const dates = getDatesForMonth(currentYear, currentMonth);
    const actualCurrentMonth = new Date().getMonth();
    const actualCurrentYear = new Date().getFullYear();

    const [checked, setChecked] = React.useState({});
    const [clickedCheckbox, setClickedCheckbox] = React.useState(null);
    const [taggedOutList, setTaggedOutList] = React.useState([]);

    React.useEffect(() => {
        getEquipment();
        getIssues();

    }, []);

    React.useEffect(() => {
        getInspections();
    }, [currentMonth]);

    const goBack = () => {
        setCurrentMonth((prev) => (prev > 0 ? prev - 1 : 11));
        setCurrentYear((prev) => (currentMonth > 0 ? prev : prev - 1));
    };

    const goForward = () => {
        setCurrentMonth((prev) => (prev < 11 ? prev + 1 : 0));
        setCurrentYear((prev) => (currentMonth < 11 ? prev : prev + 1));
    };

    const getEquipment = async () => {
        setIsLoading(true);
        try {
            const response = await EquipmentServices.getAllEquipment(token);
            setEquipmentList(response.data);
            setEquipment(response.data[0]);
            setIsLoading(false);

        } catch (error) {
            handleOpenSnackbar('error', 'Error loading equipment');
            setIsLoading(false);
        }
    };

    const getInspections = async () => {
        setIsLoading(true);
        try {
            const month = (currentMonth + 1).toString().padStart(2, '0');
            const response = await EquipmentServices.getEquipmentInspections(token, month, currentYear);
            setInspectionData(response.data);
            setIsLoading(false);
        } catch (error) {
            handleOpenSnackbar('error', 'Error loading inspections');
            setIsLoading(false);
        }
    };

    const getIssues = async () => {
        setIsLoading(true);
        try {
            const response = await EquipmentServices.getEquipmentIssues(token);
            setTaggedOutList(response.data);
            setIsLoading(false);
        } catch (error) {
            handleOpenSnackbar('error', 'Error loading issues');
            setIsLoading(false);
        }
    };

    return (
        <Box sx={{ width: '100%', maxWidth: 350, bgcolor: 'background.paper' }}>
            <Box display="flex" justifyContent="center" alignItems="center" marginBottom={2}>
                <Stack direction="row" spacing={2}>
                    <IconButton onClick={goBack}>
                        <ArrowBackIos />
                    </IconButton>
                    <Typography variant="h6">
                        {new Date(currentYear, currentMonth).toLocaleString('default', { month: 'long' })} {currentYear}
                    </Typography>
                    <IconButton onClick={goForward} disabled={currentMonth === actualCurrentMonth && currentYear === actualCurrentYear}>
                        <ArrowForwardIos />
                    </IconButton>
                </Stack>
            </Box>
            <CustomCalendar
                user={user}
                token={token}
                handleOpenSnackbar={handleOpenSnackbar}
                setIsLoading={setIsLoading}
                dates={dates}
                setOpen={setOpen}
                currentMonth={currentMonth}
                currentYear={currentYear}
                checked={checked}
                setChecked={setChecked}
                clickedCheckbox={clickedCheckbox}
                setClickedCheckbox={setClickedCheckbox}
                inspectionData={inspectionData}
                setInspectionData={setInspectionData}
                setEquipment={setEquipment}
                equipment={equipment}
                equipmentList={equipmentList}
                taggedOutList={taggedOutList}
                setTaggedOutList={setTaggedOutList}

            />
            <EquipmentInspectionDialog
                user={user}
                open={open}
                token={token}
                handleOpenSnackbar={handleOpenSnackbar}
                setIsLoading={setIsLoading}
                category={category}
                setOpen={setOpen}
                checked={checked}
                setChecked={setChecked}
                clickedCheckbox={clickedCheckbox}
                setClickedCheckbox={setClickedCheckbox}
                setInspectionData={setInspectionData}
                equipment={equipment}
                setEquipmentList={setEquipmentList}
                setTaggedOutList={setTaggedOutList}
            />
        </Box>
    );
}