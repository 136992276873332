import { fabric } from 'fabric';

const Window = (canvas, theme, darkState) => {
    const stroke = theme.palette.secondary.main;
    const fill = theme.palette.background.paper;
    const strokeWidth = 0.5;
    const left = 200;
    const top = 300 - 2.5;

    // Outer frame
    const outerFrame = new fabric.Rect({
        left: left,
        top: top,
        width: 100,
        height: 5,
        fill: fill,
        stroke: stroke,
        strokeWidth: strokeWidth,
        selectable: false,
    });

    // Inner frame
    const innerFrame = new fabric.Rect({
        left: left + 5,
        top: top + 2.5,
        width: 90,
        height: 0,
        fill: fill,
        stroke: stroke,
        strokeWidth: strokeWidth,
        selectable: false,
    });

    // Middle horizontal divider
    const middleDivider = new fabric.Line([left + 5, top + 2.5, left + 95, top + 2.5], {
        stroke: stroke,
        strokeWidth: strokeWidth,
        selectable: false,
    });

    // Left side detail
    const leftDetail = new fabric.Rect({
        left: left,
        top: top,
        width: 5,
        height: 5,
        fill: fill,
        stroke: stroke,
        strokeWidth: strokeWidth,
        selectable: false,
    });

    // Right side detail
    const rightDetail = new fabric.Rect({
        left: left + 95,
        top: top,
        width: 5,
        height: 5,
        fill: fill,
        stroke: stroke,
        strokeWidth: strokeWidth,
        selectable: false,
    });

    // Group all elements to form the window
    const windowGroup = new fabric.Group([outerFrame, innerFrame, middleDivider, leftDetail, rightDetail], {
        selectable: true,
    });

    canvas.add(windowGroup);
    return windowGroup;
};

export default Window;