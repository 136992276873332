import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useTheme } from '@mui/material/styles';
import EquipmentTaskModal from './EquipmentTaskModal';


export default function TaggedOutModal(props) {
    const { user, token, handleOpenSnackbar } = props;
    const { open, setOpen, equipment, setOpenTaggedOutDialog, slide } = props;
    const [ openEquipmentTaskModal, setOpenEquipmentTaskModal ] = React.useState(false);
    const [ taggedOut, setTaggedOut ] = React.useState(null);
    const theme = useTheme();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        border: `2px solid ${theme.palette.primary.main}`,
        boxShadow: 24,
        p: 4,
    };

    const handleClose = () => {
        setOpen(false);
        setTaggedOut(null);
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        sx={{ mb: 3 }}
                    >
                        {`Tag Out ${equipment.nickname}`}
                    </Typography>
                    <ToggleButtonGroup
                        fullWidth
                        color="primary"
                        value={taggedOut}
                        exclusive
                        onChange={(event, newValue) => {
                            setTaggedOut(newValue);
                            if (newValue === false) {
                                handleClose();
                                setOpenEquipmentTaskModal(true);
                            } else if (newValue === true) {
                                setOpenTaggedOutDialog(true);
                                setOpen(false);
                                setTaggedOut(null);
                            }
                        }}
                        aria-label="Platform"
                    >
                        <ToggleButton value={true}>Yes</ToggleButton>
                        <ToggleButton value={false}>No</ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </Modal>
            <EquipmentTaskModal
                user={user}
                token={token}
                handleOpenSnackbar={handleOpenSnackbar}
                open={openEquipmentTaskModal}
                setOpen={setOpenEquipmentTaskModal}
                equipment={equipment}
                slide={slide}
            />
        </div>
    );
};