import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useTheme } from '@mui/material/styles';
import { TextField } from '@mui/material';
import TaskServices from '../../services/Task.services';

export default function EquipmentTaskModal(props) {
    const { user, token, handleOpenSnackbar } = props;
    const { open, setOpen, equipment, slide } = props;
    const [ taggedOut, setTaggedOut ] = React.useState(null);
    
    const [isValid, setIsValid] = React.useState(true);
    const [errors, setErrors] = React.useState({});

    const initialFormValues = { description: '' };
    const [values, setValues] = React.useState(initialFormValues);

    const theme = useTheme();

    React.useEffect(() => {
    const taskDescription = `${equipment.make} ${equipment.model} ${slide.description ? slide.description : ''}`;
    
    setValues({ ...slide, description: taskDescription });
}, [open, equipment.make, equipment.model, slide.description]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        border: `2px solid ${theme.palette.primary.main}`,
        boxShadow: 24,
        p: 4,
    };

    const handleClose = () => {
        setOpen(false);
        setTaggedOut(null);
    };

    const handleValidation = () => {
        let formIsValid = true;

        if (values.description === '') {
            setErrors({ ...errors, description: 'Please enter a description' });
            formIsValid = false;
            setTimeout(() => {
                formIsValid = true;
                setErrors({ ...errors, description: null });
            }, 3000);
        }
        setIsValid(formIsValid)
        setTimeout(() => {
            setIsValid(true);
        }, 3000);
        return formIsValid ? createTask() : null
    };

    const createTask = async () => {
        const data = {
            created_by: user.id,
            assignee: equipment.manager.id,
            tasklist: 8,
            title:`Equipment Issue: ${equipment.nickname} ${equipment.make} ${equipment.model}`,
            notes: values.description,
            due: new Date(),
            subtasks:[],
            project: 12,
            service: '',
            hse: '',
            quote:'',
            created: new Date(),
            is_complete: false,
            is_deleted: false,
            is_read: false,
            is_public: true,
            completed: new Date(),
            updated: new Date(),
            attachments: []
        };
        try {
            await TaskServices.createTask(data, token);
            setOpen(false);
            handleOpenSnackbar('success', 'Task Created Successfully');
        } 
        catch (error) {
            console.log(error);
            setOpen(false);
            handleOpenSnackbar('error', 'Error creating task');
        }
    };

    const handleUpdate = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        sx={{ mb: 3 }}
                    >
                        Create Task for {equipment.manager.first_name} {equipment.manager.last_name}
                    </Typography>
                    <TextField
                        sx={{ mb: 3 }}
                        autoFocus={false}
                        margin="dense"
                        id="description"
                        name='description'
                        label="description"
                        onChange={handleUpdate}
                        value={values.description}
                        type="text"
                        multiline
                        fullWidth
                        rows={4}
                        variant="outlined"
                        helperText={errors.description === null ? '' : errors.description}
                        error={errors.description ? true : false}
                    />
                    <ToggleButtonGroup
                        fullWidth
                        color="primary"
                        value={taggedOut}
                        exclusive
                        onChange={(event, newValue) => {
                            setTaggedOut(newValue);
                            if (newValue === false) {
                                handleClose();
                            } else if (newValue === true) {
                                handleValidation();
                                setTaggedOut(null);
                            }
                        }}
                        aria-label="Platform"
                    >
                        <ToggleButton style={{ border: `2px solid ${theme.palette.secondary.main}` }} value={true}>Yes</ToggleButton>
                        <ToggleButton value={false}>No</ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </Modal>
        </div>
    );
};