import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Stack } from '@mui/material';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import TaggedOutModal from './TaggedOutModal';
import TaggedOutDialog from './TaggedOutDialog';
import EquipmentServices from '../../services/Equipment.services';

// const inspectionItems = [
//     'DAMAGE - Bent, dented or broken parts',
//     'LEAKS – Fuel, oil, radiator, hydraulics',
//     'TIRES & WHEELS – Drive wheels, steer wheels, load wheels, casters, etc.',
//     'FORKS – Secure, not bent, cracked or badly worn',
//     'CHAINS, CABLES & HOSES – In place',
//     'BATTERY – Battery cable and connector condition. Cable routing (not outside of truck). Battery retainer securely in place'
// ];



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function EquipmentInspectionDialog(props) {
    const { user, token, handleOpenSnackbar, setIsLoading } = props;
    const { open, setOpen, equipment, setInspectionData, setTaggedOutList, category } = props;
    const [openTaggedOut, setOpenTaggedOut] = React.useState(false);
    const [openTaggedOutDialog, setOpenTaggedOutDialog] = React.useState(false);
    const { setEquipmentList } = props;
    const { checked, setChecked } = props;
    const { clickedCheckbox, setClickedCheckbox } = props;
    const [inspectionItems, setInspectionItems] = React.useState([]);
    const [thumbsUpPressed, setThumbsUpPressed] = React.useState(false);
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const sliderRef = React.useRef(null);
    const theme = useTheme();

    React.useEffect(() => {
        getInspectionItems();
    }, []);

    // React.useEffect(() => {
    //     console.log('currentSlide', inspectionItems[currentSlide]);
    // }, [currentSlide]);

    const SlickDots = styled('div')`
        & li button:before {
        color: ${theme.palette.mode === 'dark' ? 'white' : 'black'} !important;
        margin-top: 30px !important;
        }
    `;



    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        // centerMode: true,
        appendDots: dots => (
            <SlickDots style={{ marginBottom: '15px', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                <ul style={{ margin: 0, padding: 0, listStyleType: 'none', pointerEvents: 'none' }}> {dots} </ul>
            </SlickDots>
        ),

    };

    const handleClose = () => {
        setOpen(false);
        setCurrentSlide(0);
        setClickedCheckbox(null);
        setThumbsUpPressed(false);
    };

    const handleCompleteInspection = () => {
        createInpection();
        setChecked(prevChecked => ({ ...prevChecked, [`${clickedCheckbox.date}-${clickedCheckbox.equipment.nickname}`]: true }));
        handleClose();
    };

    const handleThumbsDown = () => {
        setOpenTaggedOut(true)
    };

    const getInspectionItems = async () => {
        try {
            const response = await EquipmentServices.getInspectionItems(token, category.id);
            setInspectionItems(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const createInpection = async () => {
        setIsLoading(true);
        try {
            const data = {
                equipment: equipment.id,
                created_by: user.id,
                date: new Date(),
            };
            const response = await EquipmentServices.createEquipmentInspection(data, token);
            setInspectionData(prevData => [...prevData, response.data]);
            setIsLoading(false);
            handleOpenSnackbar('success', 'Inspection created successfully');
        } 
        catch (error) {
            handleOpenSnackbar('error', 'Error  creating inspection');
            setIsLoading(false);
        }
    };

    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {category.description} Inspection
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers sx={{ height: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Slider
                        ref={sliderRef}
                        {...settings}
                        className='mySlide'
                        afterChange={setCurrentSlide}
                        arrows={false}

                    >
                        {inspectionItems.map((item, index) => (
                            <div key={index} className="mySlide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px' }}>
                                    <Typography>{item.description}</Typography>
                                </div>
                            </div>
                        ))}
                    </Slider>

                    <div sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                        <Stack direction="row" spacing={3} sx={{ justifyContent: 'center', alignItems: 'end' }}>
                            <IconButton
                                size='large'
                                sx={{ width: '75px', height: '75px' }}
                                color="error"
                                onClick={handleThumbsDown}
                            >
                                <ThumbDownIcon sx={{ width: '50px', height: '50px' }} />
                            </IconButton>
                            <IconButton size='large' sx={{ width: '75px', height: '75px' }} color="success" onClick={() => {
                                sliderRef.current.slickNext();
                                if (currentSlide === inspectionItems.length - 1) {
                                    setThumbsUpPressed(true);
                                }
                            }}>
                                <ThumbUpIcon sx={{ width: '50px', height: '50px' }} />
                            </IconButton>
                        </Stack>
                    </div>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', m: 2 }}>
                    <Button
                        disabled={!thumbsUpPressed}
                        color='secondary'
                        onClick={handleCompleteInspection}
                        variant='contained'>
                        Complete Inspection
                    </Button>
                </DialogActions>
                <TaggedOutModal
                    user={user}
                    token={token}
                    handleOpenSnackbar={handleOpenSnackbar}
                    open={openTaggedOut}
                    setOpen={setOpenTaggedOut}
                    setOpenTaggedOutDialog={setOpenTaggedOutDialog}
                    equipment={equipment}
                    slide={inspectionItems[currentSlide]}
                />
                <TaggedOutDialog
                    user={user}
                    token={token}
                    handleOpenSnackbar={handleOpenSnackbar}
                    setIsLoading={setIsLoading}
                    open={openTaggedOutDialog}
                    setOpen={setOpenTaggedOutDialog}
                    setEquipmentDialog={setOpen}
                    setEquipmentList={setEquipmentList}
                    equipment={equipment}
                    setTaggedOutList={setTaggedOutList}
                />
            </BootstrapDialog>
        </React.Fragment>
    );
}