import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import PictureInPictureIcon from '@mui/icons-material/PictureInPicture';
import SaveIcon from '@mui/icons-material/Save';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import WindowIcon from '@mui/icons-material/Window';

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
        top: theme.spacing(3),
        left: theme.spacing(2),
    },
}));


export default function SpeedDialControls(props) {
    const { handleAddDoor, handleAddWindow, handleAddPenPanel, handleSaveImage } = props;

    const actions = [
        { icon: <MeetingRoomIcon />, onClick: handleAddDoor, name: 'Add Door' },
        { icon: <WindowIcon />, onClick: handleAddWindow, name: 'Add Window' },
        { icon: <PictureInPictureIcon />, onClick: handleAddPenPanel, name: 'Add Pen Panel' },
        { icon: <SaveIcon />, onClick: handleSaveImage, name: 'Save Image' },
    ];
    return (
        <StyledSpeedDial
            ariaLabel="SpeedDial"
            icon={<SpeedDialIcon />}
            direction="down"
            classes={{ fab: 'MuiFab-root' }} // Add this line to target the Fab component
            sx={{ '& .MuiFab-root': { boxShadow: 'none' } }} // Add this line to remove the shadow
        >
            {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    onClick={action.onClick}
                    tooltipTitle={action.name}
                />
            ))}
        </StyledSpeedDial>
    );
}