import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function EquipmentTypePicker(props) {
    const { categories, setCategory } = props;
    const { value, setValue }= props;
    const { isLoading, setIsLoading } = props;

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <FormControl fullWidth >
            <InputLabel id="equipment-type-picker-label">Equipment Categories</InputLabel>
            <Select
                disabled={isLoading}
                labelId="equipment-type-select-label"
                id="EquipmentTypePicker"
                value={value}
                label="Equipment Categories"
                onChange={handleChange}
            >
                {categories.map(v => (
                    <MenuItem
                        key={v.id}
                        value={v}
                    >   {v.description}
                    </MenuItem>
                )
                )}
            </Select>
        </FormControl>
    );
};