import React from 'react';
import { Container, Typography, Divider, Box} from '@mui/material';
import EquipmentCalendar from '../components/Equipment/EquipmentCalendar';
import EquipmentTypePicker from '../components/Equipment/EquipmentTypePicker';
import EquipmentServices from '../services/Equipment.services';
import Loading from '../components/Loading';

export default function Equipment(props) {
    const { user, token, handleOpenSnackbar, darkState } = props
    const [ categories, setCategory ] = React.useState([]);
    const [ value, setValue ] = React.useState('')
    const [ isLoading, setIsLoading ] = React.useState(false);

    const getEquipmentTypes = async () => {
        setIsLoading(true);
        try {
            const response = await EquipmentServices.getAllTypes(token);
            setCategory(response.data);
            setIsLoading(false);
        } catch (error) {
            handleOpenSnackbar('error', 'Error loading equipment types');
            setIsLoading(false);
        }
    };

    React.useEffect(() => {
        getEquipmentTypes();
    }
    , []);

    return (
        <Container
            component="span"
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                height: '100%'
            }}>
            <Box sx={{ width: '100%', maxWidth: 400, bgcolor: 'background.paper' }}>
                <EquipmentTypePicker 
                    categories={categories}
                    setCategory={setCategory}
                    value={value}
                    setValue={setValue}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                />
            </Box>
            {value && 
                <div>
                    <Typography sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        height: '100%',
                        mt: 3,
                    }}

                        variant="h5" gutterBottom>
                        INSPECTION CALENDAR
                    </Typography>
                    <Divider sx={{ mb: 3 }} />
                    <EquipmentCalendar 
                        user={user}
                        token={token}
                        handleOpenSnackbar={handleOpenSnackbar}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        darkState={darkState}
                        category={value}
                    />
                </div>
            }
            <Loading 
                open={isLoading} 
            />
        </Container>
    );
};