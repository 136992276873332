import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { Stack, IconButton, Typography, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';


import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';


import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';

import moment from 'moment';

import DrawingDialog from '../DrawingDialog';

const EquipmentDetailsDialog = (props) => {
    const { open, setOpen, equipment, taggedOutList } = props;
    const [isTaggedOut, setIsTaggedOut] = useState(false);
    const [openCollapse, setOpenCollapse] = React.useState(false);

    const [clickedIndex, setClickedIndex] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [openDrawingDialog, setOpenDrawingDialog] = useState(false);
    const [drawing, setDrawing] = useState(null);


    const theme = useTheme();

    useEffect(() => {
        const taggedOut = taggedOutList.some(taggedOut => taggedOut.equipment.id === equipment.id && taggedOut.is_resolved === false);
        setIsTaggedOut(taggedOut);
    }, [taggedOutList, equipment]);

    const handleClose = () => {
        setOpen(false);
        setOpenCollapse(false);
    };

    const handleClick = () => {
        setOpenCollapse(!openCollapse);
    };

    const handleClickPopper = (index) => (event) => {
        setAnchorEl((prevAnchorEl) => {
            if (clickedIndex === index && prevAnchorEl) {
                // If the clicked button is the same as the currently open button, close the Popper
                setClickedIndex(null);
                return null;
            } else {
                // Otherwise, open the Popper for the clicked button
                setClickedIndex(index);
                return event.currentTarget;
            }
        });
    };

    return (
        <div>
            <Dialog
                fullWidth
                fullScreen
                open={open}
                onClose={handleClose}
                scroll={'paper'}
            >
                <DialogTitle>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Stack>
                            <Typography variant="h6">
                                {equipment.nickname}
                            </Typography>
                            <Typography variant="subtitle1" color={isTaggedOut ? theme.palette.error.main : theme.palette.success.main}>
                                {isTaggedOut ? 'Tagged Out' : 'In Service'}
                            </Typography>
                        </Stack>
                        <div>
                            <IconButton
                                edge="end"
                                aria-label="close"
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                </DialogTitle>
                <Divider />

                <DialogContent>
                    <List
                        dense
                        disablePadding={true}
                        sx={{
                            width: '100%',
                            bgcolor: 'background.paper',
                            border: `2px solid ${isTaggedOut ? theme.palette.error.main : theme.palette.success.main}`,
                        }}
                    >
                        <ListItem divider>
                            <ListItemText
                                primary="Manager"
                                secondary={equipment.manager ? equipment.manager.first_name + ' ' + equipment.manager.last_name : ''}
                            />
                        </ListItem>
                        <ListItem divider>
                            <ListItemText
                                primary="Make"
                                secondary={equipment.make}
                            />
                        </ListItem>
                        <ListItem divider>
                            <ListItemText
                                primary="Model"
                                secondary={equipment.model}
                            />
                        </ListItem>
                        <ListItem divider>
                            <ListItemText
                                primary="Year"
                                secondary={equipment.year}
                            />
                        </ListItem>
                        <ListItem divider>
                            <ListItemText
                                primary="Color"
                                secondary={equipment.color}
                            />
                        </ListItem>
                        <ListItem divider>
                            <ListItemText
                                primary="Weight Capacity"
                                secondary={equipment.weight_capacity}
                            />
                        </ListItem>
                        <ListItem divider>
                            <ListItemText
                                primary="Electric"
                                secondary={equipment.is_electric ? 'Yes' : 'No'}
                            />
                        </ListItem>
                        {equipment && equipment.documents && equipment.documents.length > 0 &&
                            <div>
                                <ListItem divider>
                                    <ListItemButton
                                        sx={{ ml: 0, pl: 0 }}
                                        onClick={() => {
                                            setDrawing(equipment.documents[0]);
                                            setOpenDrawingDialog(true);
                                        }}
                                    >
                                        <ListItemText
                                            primary="Documents"
                                            secondary={
                                                equipment.documents.map((document, index) => {
                                                    return (
                                                        <Box key={index} component="span">
                                                            {document.title}
                                                            <br />
                                                        </Box>
                                                    )
                                                })
                                            }
                                        />
                                    </ListItemButton>
                                </ListItem>
                                {drawing &&
                                    <DrawingDialog
                                        openDrawingDialog={openDrawingDialog}
                                        setOpenDrawingDialog={setOpenDrawingDialog}
                                        drawing={drawing}
                                    />
                                }
                            </div>
                        }
                    </List>
                    {taggedOutList.some(taggedOut => taggedOut.equipment.id === equipment.id) &&
                        <List
                            dense
                            disablePadding={true}
                            sx={{
                                mt: 3,
                                width: '100%',
                                bgcolor: 'background.paper',
                                border: `2px solid ${isTaggedOut ? theme.palette.error.main : theme.palette.success.main}`,
                            }}
                        >
                            <ListItem divider>
                                <ListItemButton sx={{ pl: 0, pt: 1, pb: 1 }} onClick={handleClick}>
                                    <ListItemText primary="Equipment Tag Out Log" />
                                    {openCollapse ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                            </ListItem>
                            <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {taggedOutList.map((taggedOut, index) => {
                                        if (taggedOut.equipment.id === equipment.id) {
                                            const openPopper = clickedIndex === index && Boolean(anchorEl);
                                            const id = openPopper ? 'simple-popper' : undefined;

                                            return (
                                                <React.Fragment key={taggedOut.id}>
                                                    <ListItemButton
                                                        divider
                                                        sx={{ pl: 4 }}
                                                        onClick={handleClickPopper(index)}
                                                    >
                                                        <ListItemIcon>
                                                            {taggedOut.is_resolved ? <CheckIcon color='success' /> : <WarningIcon color='error' />}
                                                        </ListItemIcon>
                                                        <Box display="flex" justifyContent="center" alignItems="center">
                                                            <ListItemText
                                                                primary='Created By'
                                                                secondary={
                                                                    <Box component="span">
                                                                        {taggedOut.created_by.first_name + ' ' + taggedOut.created_by.last_name}
                                                                        <br />
                                                                        Date: {moment(taggedOut.date).format("MM/DD/YYYY")}
                                                                    </Box>
                                                                }
                                                            />
                                                            {taggedOut.is_resolved &&
                                                                <Box display="flex" alignItems="center">
                                                                    <Divider sx={{ mr: 1, ml: 1 }} orientation="vertical" flexItem />
                                                                    <ListItemText
                                                                        primary='Resolved By'
                                                                        secondary={
                                                                            <Box component="span">
                                                                                {taggedOut.resolved_by.first_name + ' ' + taggedOut.resolved_by.last_name}
                                                                                <br />
                                                                                Date: {moment(taggedOut.resolved_datew).format("MM/DD/YYYY")}
                                                                            </Box>
                                                                        }
                                                                    />
                                                                </Box>
                                                            }
                                                        </Box>
                                                    </ListItemButton>
                                                    {openPopper && (
                                                        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                                                            {/* <Popper id={id} open={openPopper} anchorEl={anchorEl} style={{ zIndex: 9999 }}>
                                                            <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
                                                                {taggedOut.description}
                                                            </Box>
                                                        </Popper> */}
                                                            <Popper
                                                                // Note: The following zIndex style is specifically for documentation purposes and may not be necessary in your application.
                                                                sx={{ zIndex: 9999 }}
                                                                open={openPopper}
                                                                anchorEl={anchorEl}
                                                                placement={'top'}
                                                                transition
                                                            >
                                                                {({ TransitionProps }) => (
                                                                    <Fade {...TransitionProps} timeout={350}>
                                                                        <Paper sx={{ bgcolor: theme.palette.secondary.main }}>
                                                                            <Typography sx={{ p: 2 }}>{taggedOut.description}</Typography>
                                                                        </Paper>
                                                                    </Fade>
                                                                )}
                                                            </Popper>
                                                        </ClickAwayListener>
                                                    )}
                                                </React.Fragment>

                                            )
                                        }
                                    })}
                                </List>
                            </Collapse>
                        </List>
                    }

                </DialogContent>

                <DialogActions>

                </DialogActions>
            </Dialog >
        </div >
    );
}

export default EquipmentDetailsDialog;

