import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack, Typography, IconButton } from '@mui/material';
import  Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import Transition from '../DialogTransistion'
import moment from 'moment';

export default function DoorServiceReportDialog(props) {
    const { open, setOpen } = props;
    const { report } = props;

    const handleClose = () => {
        setOpen(false);
    };
    
    return (
        <div>
            <Dialog 
                TransitionComponent={Transition}
                fullWidth 
                fullScreen
                open={open} 
                onClose={handleClose}
                scroll={'paper'}
                >
                <DialogTitle>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div>
                            {report? `Report ${report.number}` : ''}
                        </div>
                        <div>
                        <IconButton 
                            edge="end" 
                            aria-label="close"
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                        </div> 
                    </div>
                </DialogTitle>
                <Divider/>
                <DialogContent>
                    <Stack spacing={2}>
                        <Stack direction="row" spacing={1}>
                            <Typography sx={{color: 'text.secondary'}}>
                                Technician:
                            </Typography>
                            <Typography sx={{fontWeight: "bold"}}>
                                {report ? `${report.technician.first_name} ${report.technician.last_name}` : ''}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <Typography sx={{color: 'text.secondary'}}>
                                Date:
                            </Typography>
                            <Typography sx={{fontWeight: "bold"}}>
                                {report ? moment(report.date).format('MM/DD/YYYY') : ''}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <Typography sx={{color: 'text.secondary'}}>
                                Service Type:
                            </Typography>
                            <Typography sx={{fontWeight: "bold"}}>
                                {report ? report.service_type : ''}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <Typography sx={{color: 'text.secondary'}}>
                                Status:
                            </Typography>
                            <Typography sx={{fontWeight: "bold"}}>
                                {report ? report.status : ''}
                            </Typography>
                        </Stack>
                        <Divider color='primary' sx={{ borderWidth: '2px' }} />
                        <Stack spacing={1}>
                            <Typography sx={{color: 'text.secondary'}}>
                                Problem Reported:
                            </Typography>
                            <Typography sx={{fontWeight: "bold"}}>
                                {report ? report.problem_reported : ''}
                            </Typography>
                        </Stack>
                        <Divider color='primary' sx={{ borderWidth: '2px' }} />
                        <Stack spacing={1}>
                            <Typography sx={{color: 'text.secondary'}}>
                                Service Rendered:
                            </Typography>
                            <Typography sx={{fontWeight: "bold"}}>
                                {report ? report.service_rendered : ''}
                            </Typography>
                        </Stack>
                    </Stack>
                {/* <Stack spacing={2}>
                    <Stack direction="row" spacing={1}>
                        <Typography sx={{color: 'text.secondary'}}>
                            Project:
                        </Typography>
                        <Typography sx={{fontWeight: "bold"}}>
                            {DCN ? (DCN.project !== null
                                ? DCN.project.number
                                : DCN.service !== null
                                ? DCN.service.number
                                : DCN.hse !== null
                                ? DCN.hse.number
                                : DCN.quote.number) : ''}
                        </Typography>
                    </Stack>
                    <Divider/>
                    <Stack direction="row" spacing={1}>
                        <Typography sx={{color: 'text.secondary'}}>
                            Rev:
                        </Typography>
                        <Typography sx={{fontWeight: "bold"}}>
                            {DCN.rev}
                        </Typography>
                    </Stack>
                    <Divider/>
                    <Stack direction="row" spacing={1}>
                        <Typography sx={{color: 'text.secondary'}}>
                            Date:
                        </Typography>
                        <Typography sx={{fontWeight: "bold"}}>
                            {formatDate(DCN.created)}
                        </Typography>
                    </Stack>
                    <Divider/>
                    <Stack direction="row" spacing={1}>
                        <Typography sx={{color: 'text.secondary'}}>
                            Created By:
                        </Typography>
                        <Typography sx={{fontWeight: "bold"}}>
                            {DCN.created? `${DCN.created_by.first_name} ${DCN.created_by.last_name}` : ''}
                        </Typography>
                    </Stack>
                    <Divider/>
                    <Stack direction="row" spacing={1}>
                        <Typography sx={{color: 'text.secondary'}}>
                            External:
                        </Typography>
                        <Typography sx={{fontWeight: "bold"}}>
                            {DCN.is_external ? 'Yes' : 'No'}
                        </Typography>
                    </Stack>
                    <Divider color='primary' sx={{ borderWidth: '2px' }} />
                    <Stack spacing={1}>
                        <Typography sx={{color: 'text.secondary'}}>
                            Comments:
                        </Typography>
                        <Typography sx={{fontWeight: "bold"}}>
                            {DCN.comments}
                        </Typography>
                    </Stack>
                    
                </Stack> */}
                </DialogContent>
                    <Divider/>
                    <DialogActions>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                        >
                            <Button
                                color='primary' 
                                variant="contained" 
                                onClick={handleClose}
                            >Close
                            </Button>
                        </Stack>
                    </DialogActions>
            </Dialog>
        </div>
    )
};